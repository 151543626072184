import { useState, useEffect } from "react";
import { Box, Typography, Card } from "@mui/material";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";

const VenueProfileMenuCard = ({ item, setOrderItem,width }) => {
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getImage = async () => {
      try {
        const res = await fetchImage(item?.photoURL);
        setImageURL(res);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    if (item?.photoURL) {
      getImage();
    }
  }, [item]);

  const formatPrice = (value) => {
    return Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Box
      onClick={() => setOrderItem(item)}
      sx={{
        display: "flex",
        flexDirection: "row",
        borderTop: "1px solid rgba(0, 0, 0, 0.15)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
        borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
        borderRight: "1px solid rgba(0, 0, 0, 0.15)",
        borderRadius: "5px",
        padding: "10px",
        cursor: "pointer",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        width: `${width?width:'calc(49%)'}`,
        "@media (max-width: 1440px)": {
          width:`${width?width:'calc(49%)'}`,
        },
        "@media (max-width: 1000px)": {
          width: `${width?width:'calc(100%)'}`,
        },
      }}
    >
      <Card
        sx={{
          flexShrink: 0,
          flexGrow: 0,
          width: "100px",
          height: "100px",
          borderRadius: 1,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Image
          alt={item.name}
          src={imageURL ? imageURL : "/assets/placeholder.png"}
          ratio="1/1"
          sx={{
            borderRadius: 0,
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
        />
      </Card>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "15px",
          justifyContent: "center",
          flexGrow: 1,
          marginBottom: "8px",
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>
          {item.name.length > 15
            ? `${item.name.substring(0, 20)}...`
            : item.name}
        </Typography>
        <Typography
          sx={{
            color: "#F08203",
            fontSize: "16px",
            "@media (max-width: 1440px)": {
              fontSize: "14px",
            },
          }}
        >
          Rs. {formatPrice(parseFloat(item.price))}
        </Typography>
        <Typography
          sx={{
            textAlign: "justify",
            fontSize: "16px",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
            "@media (max-width: 1440px)": {
              fontSize: "12px",
              marginRight: "20px",
            },
          }}
        >
          {item?.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default VenueProfileMenuCard;
